<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>FILTRAR PEDIDOS</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    ref="order_date"
                                    v-model="form.order_date"
                                    label="Fecha de pedido"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.order_date" 
                                @input="menuFrom = false" 
                                no-title
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="customer_id"
                            v-model="form.customer_id"
                            :items="customers"
                            label="Seleccione cliente"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="user_roles != 'vendedor'">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.register_by"
                            :items="sellers"
                            label="Vendedor"
                            outlined
                            :item-text="customTextSeller"
                            item-value="id"
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.status"
                            :items="status_options"
                            label="Estado"
                            outlined
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" 
                            @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" 
                            @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'Filters',
    data () {
        return {
            drawer: false,
            menuFrom: false,
            status_options: ['REGISTRADO', 'DESPACHADO', 'ANULADO'],
            form: {
                order_date: this.$moment().format('YYYY-MM-DD'),
                register_by: null,
                customer_id: null,
                status: null,
                date_init: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
                date_end: this.$moment().format('YYYY-MM-DD')
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'user_roles'
        ]),
        ...mapState('customers', [
            'customers'
        ]),
        ...mapState('users', [
            'sellers'
        ])
    },
    methods: {
        ...mapActions('orders', [
            'getOrders'
        ]),
        show () {
            this.drawer = !this.drawer;
        },
        customText(item){
            let text = item.fullname
            
            return text
        },
        customTextSeller(item){
            let text = item.employee_name
            
            return text
        },
        clearData () {
            this.form.order_date = null;
            this.form.register_by = null;
            this.form.customer_id = null;
            this.form.status = null;
        },
        closeForm() {
            this.drawer = false;
        },
        async handleClearFilters() {
            this.clearData();
            await this.getOrders(this.form);
        },
        async handleSearch() {
            await this.getOrders(this.form);
            this.closeForm();
        }
    },
    mounted () {
        this.clearData()
    }
}
</script>

<style scoped>

</style>