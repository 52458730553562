<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="1" class="d-md-none pb-1 pt-0" >
                    <v-icon size="16" @click="handleViewMenu" class="ml-2 mr-2">
                        fas fa-bars
                    </v-icon>
                </v-col>
                <v-col :md="6" :sm="6" cols="5" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col :md="6" :sm="6" cols="12" class="pt-1">
                    <span class="headline font-weight-medium">Lista de pedidos</span>
                </v-col>
                <!--<v-col cols="6" class="pt-1 d-none d-sm-block">
                    <v-btn v-if="this.$hasPermision('pedidos.detalle_embutidos')"
                        dark color="amber" 
                        class="float-right ml-2" height="40" @click="handleViewDownloadEmbutidos">
                        Detalle de embutidos
                    </v-btn>
                    <v-btn v-if="this.$hasPermision('pedidos.detalle_abarrotes')"
                        dark color="indigo" 
                        class="float-right" height="40" @click="handleViewDetailBySeller">
                        Detalle de abarrotes
                    </v-btn>                    
                    <v-btn v-if="this.$hasPermision('pedidos.detalle_cliente')"
                        dark color="success" 
                        class="float-right mr-2" height="40" @click="handleViewDownload">
                        Detalle por cliente
                    </v-btn>
                </v-col>-->
            </v-row>
            <v-row>
                <v-col :md="4" :sm="4" cols="12" class="pt-1">
                    <v-text-field
                        v-model="search_customer"
                        label="Buscar por cliente"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                        @keydown="handleInputSearchSeller">
                    </v-text-field>              
                </v-col>
                <v-col :md="6" :sm="6" cols="6" class="pt-1">
                    <v-btn class="w-btn-search" height="40" @click="handleSearch">
                        Buscar
                    </v-btn>
                </v-col>
                <v-col :md="2" :sm="2" cols="6" class="pt-1">
                    <v-btn dark color="blue" 
                        class="float-right" height="40" @click="handleViewFilter">
                        Filtrar Pedidos
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="orders"
                            :items-per-page="10"
                            item-key="id"
                            class="elevation-1 w-table"
                            height="57vh"

                            show-select
                            :single-select="true"
                            @item-selected="handleSelectItem"
                            :loading="loading"
                            :options.sync="options"
                            :server-items-length="paginated.total"
                            loading-text="Loading... Please wait"
                        >
                            <template v-slot:item.order_hour="{item}">
                                <span>{{ item.created_at | moment('HH:mm:ss') }}</span>
                            </template>

                            <template v-slot:item.customer_id="{item}">
                                <span>{{ item.customer_name }}</span>
                            </template>

                            <template v-slot:item.register_by="{item}">
                                <span>{{ item.employee_name }}</span>
                            </template>

                            <template v-slot:item.total="{item}">
                                <span>{{ item.total | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.status="{item}">
                                <span :class="'label-status orden-' + item.status">
                                    {{ item.status }}
                                </span>
                            </template>

                            <template v-slot:item.actions="{item}">
                                <v-icon v-if="item.status == 'registrado'" 
                                    size="18" 
                                    color="blue"
                                    @click="handleViewDetail(item)"
                                    >
                                    fas fa-bars
                                </v-icon>
                                <v-icon v-if="item.status == 'registrado'" 
                                    size="18" 
                                    color="error"
                                    class="ml-3"
                                    @click="handleCancelOrder(item)"
                                    >
                                    far fa-times-circle
                                </v-icon>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>


        </template>

        <download-filter ref="downloadFilter"></download-filter>

        <Filters ref="filters"></Filters>

        <detail-seller ref="detailSeller"></detail-seller>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import router from '@/router/index';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import DownloadFilter from '../../../components/orders/DownloadFilter';
import DetailSeller from '../../../components/orders/DetailSeller';
import Filters from '../../../components/orders/Filters';

export default {
    name: 'Orders',
    components: {
        DownloadFilter,
        OfficeLogin,
        Filters,
        DetailSeller
    },
    watch: {
    options: {
      handler() {
        this.loading = true;
        console.log(this.options);
        this.perPage = this.options.itemsPerPage;
        this.pageSelected = this.options.page;
        const search = this.search_product;
        console.log(search);
        if (
          this.filtersPaginated.name != null ||
          this.filtersPaginated.sku != null ||
          this.filtersPaginated.barcode != null ||
          this.filtersPaginated.category_id != null ||
          this.filtersPaginated.brand_id != null ||
          this.filtersPaginated.unit_id != null ||
          this.filtersPaginated.office_id != null
        ) {
          this.getOrders({
            paginated: true,
            page: this.pageSelected,
            itemsPerPage: this.perPage,
            name: this.filtersPaginated.name,
            sku: this.filtersPaginated.sku,
            barcode: this.filtersPaginated.barcode,
            category_id: this.filtersPaginated.category_id,
            brand_id: this.filtersPaginated.brand_id,
            unit_id: this.filtersPaginated.unit_id,
            office_id: this.filtersPaginated.office_id,
          })
            .then((res) => (this.loading = false))
            .catch((err) => (this.loading = false));
        } else {
          if (search != null && search.trim().length > 0) {
            // this.perPage = -1
            this.getOrders({
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
              name: search,
            })
              .then((res) => (this.loading = false))
              .catch((err) => (this.loading = false));
          } else {
            this.getOrders({
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
            })
              .then((res) => (this.loading = false))
              .catch((err) => (this.loading = false));
          }
        }
      },
      deep: true,
    },
  },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Ventas',
                    disabled: true,
                    href: '',
                }
            ],
            search_customer: null,
            headers: [
                { text: 'FECHA PEDIDO', value: 'order_date', sortable: false, width: 110 },
                { text: 'HORA PEDIDO', value: 'order_hour', sortable: false, width: 110 },
                { text: 'CLIENTE', value: 'customer_id', sortable: false },
                { text: 'DIRECCIÓN', value: 'address', sortable: false, width: 400 },
                { text: 'PRE VENDEDOR', value: 'register_by', sortable: false },
                { text: 'TOTAL', value: 'total', sortable: false, align: 'end' },
                { text: 'ESTADO', value: 'status', sortable: false },
                { text: '', value: 'actions', sortable: false, width: 80 },
            ],
            date_init: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
            date_end: this.$moment().format('YYYY-MM-DD'),

            loading: false,
            dataTable: { page: 1 },
            options: {},
            pageSelected: 1,
            perPage: 10,
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id',
            'till_user_id',
            'user_roles'
        ]),
        ...mapState('tills', [
            'tills',
            'selected_till'
        ]),
        ...mapState('orders', [
            'orders',
            "paginated",
            "filtersPaginated",
        ])
    },
    mounted() {
        this.changeFilterPaginated({
            name: null,
            company_id: null,
        });
    },
    methods: {
        ...mapActions('authentication', [
            'setShowMenu'
        ]),
        ...mapActions('customers', [
            'getCustomers'
        ]),
        ...mapActions('orders', [
            'getOrders',
            'setOrderSelected',
            'setSale',
            'cancel'
        ]),
        ...mapActions('users', [
            'getSellers'
        ]),
        ...mapActions('tills', [
            'getAvailable',
            'select_till'
        ]),
        handleViewMenu () {
            this.setShowMenu(true);
        },
        handleInputSearchSeller(KeyboardEvent){
            if (KeyboardEvent.code == 'Enter') {
                this.handleSearch();
            }
        },
        async onPageChange(page) {
            this.loading = true;
            await this.getProducts({ page: page, paginated: true })
                .then((result) => (this.loading = false))
                .catch((errr) => (this.loading = false));
        },
        async handleSearch() {
            let filters = {
                fullname: this.search_customer,
                date_init: this.date_init,
                date_end: this.date_end
            }
            await this.getOrders(filters)
        },
        handleShowFilters () {
            console.log('filtrar');
        },
        handleViewDetail (order) {
            this.setOrderSelected(order);
            router.push('/app/ventas/detalle-pedido');
        },
        handleCancelOrder (order) {
            this.$swal({
                title: "¿Desea confirmar?",
                text: "Confirma que desea anular el pedido",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if(result.value){
                    if(result.value === true){
                        this.cancel({
                            order_id: order.id
                        });
                    }
                }                    
            })
        },
        handleViewDownload () {
            this.$refs.downloadFilter.show();
        },
        handleViewDetailBySeller () {
            this.$refs.detailSeller.show('abarrotes');
        },
        handleViewDownloadEmbutidos () {
            this.$refs.detailSeller.show('embutidos');
        },
        handleViewFilter () {
            this.$refs.filters.show();
        },
        async getCurrentUserTill(){
            let filters = {
                till_id: this.till_user_id
            }
            await this.getAvailable(filters);
            if (this.tills.length > 0) {
                this.select_till(this.tills[0]);
            }
        },
        loadData () {
            this.loading = true;
            this.getCurrentUserTill();
            /*this.getOrders({
                date_init: this.date_init,
                date_end: this.date_end
            });*/
            this.setSale(null);
            this.getSellers();
            this.getCustomers();
            let filters = {
                office_id: this.office_id,
                paginated: true,
            };
        }
    },
    created () {
        this.loadData();
    }
}
</script>

<style scoped>
.orden-registrado{
    border: 1px solid #3F51B5;
    color: #3F51B5;
}

.orden-despachado{
    border: 1px solid #4CAF50;
    color: #4CAF50;
}

.orden-anulado{
    border: 1px solid #F44336;
    color: #F44336;
}
</style>