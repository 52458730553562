<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Descargar Detalle por cliente</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    ref="order_date"
                                    v-model="form.order_date"
                                    label="Fecha de pedido"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    :rules="[() => !!form.order_date || 'Debe ingresar una fecha']"
                                    hide-details="auto"
                                    required
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.order_date" 
                                @input="menuFrom = false" 
                                no-title
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.register_by"
                            :items="sellers"
                            label="Vendedor"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-2 pl-3 pt-2 pr-3">
            <template>
                <v-row>
                    <v-col cols="6" class="pt-1 pb-1">
                        <v-btn block height="40" dark color="red" 
                            @click="handleCancel">
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pt-1 pb-1">
                        <v-btn dark color="success" block height="40" 
                            @click="handleDownload">
                            Descargar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'DownloadFilter',
    data () {
        return {
            drawer: false,
            menuFrom: false,
            form: {
                order_date: this.$moment().format('YYYY-MM-DD'),
                register_by: null
            }
        }
    },
    computed: {
        ...mapState('orders', [
            'file_name'
        ]),
        ...mapState('users', [
            'sellers'
        ])
    },
    methods: {
        ...mapActions('orders', [
            'downloadDetail'
        ]),
        show () {
            this.clearData();
            this.drawer = !this.drawer;
        },
        async clearData () {
            this.form.register_by = null;
            await this.clearErrors();
            this.form.order_date = this.$moment().format('YYYY-MM-DD');
        },
        closeForm () {
            this.clearData();
            this.drawer = false;
        },
        customText(item){
            let text = item.employee_name
            
            return text
        },
        handleCancel () {
            this.closeForm();
        },
        async handleDownload () {
            if (!this.existErrors()) {
                await this.downloadDetail(this.form);
                if (this.file_name) {
                    let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                    window.open(url);
                }
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>